import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
import { Code } from '@uc-common/code';
import { Link } from '~/src/components/Link';
import { Button, ButtonTypeEnum } from '@uc-common/button';
import { SectionWrapper } from '~/src/components/CommonStyles';
import { track } from '~/src/utils/tracker.js';
export const frontmatter = {
  title: 'How to transfer your files to Uploadcare',
  description: 'This guide will help you copy your files from anoher storage to Uploadcare with the help of our Migro tool.',
  header: 'How to transfer your files to Uploadcare',
  noindex: true
};
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Paragraph = makeShortcode("Paragraph");
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`When moving your files to Uploadcare from other instruments, the need to transfer all files might look scary. Especially, if you have thousands of files. However, this doesn’t have to be a manual migration. Uploadcare has created the `}<Link to="https://github.com/uploadcare/migro" target="_blank" mdxType="Link">{`Migro tool`}</Link>{` to serve this purpose. This solution works both when you move from another CDN, or from a DIY solution.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#install-migro"
        }}>{`Install Migro`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#run-migro"
        }}>{`Run Migro`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#or-use-uploadcare-without-moving"
        }}>{`Or use Uploadcare without moving`}</a></li>
    </ul>
    <Paragraph mdxType="Paragraph">
  <SectionWrapper mdxType="SectionWrapper">
    <Button as={Link} to='/accounts/signup/' onClick={() => track('Sign up')} type={ButtonTypeEnum.PRIMARY} outline={false} ghost mdxType="Button">
        Sign up and start for free
    </Button>
  </SectionWrapper>
    </Paragraph>
    <img {...{
      "src": "https://ucarecdn.com/8fb2a0f0-321e-48c7-a36c-2320d9905379/-/scale_crop/1200x642/smart/-/quality/best/",
      "alt": "Migro library on GitHub"
    }}></img>
    <h2 {...{
      "id": "install-migro"
    }}><a parentName="h2" {...{
        "href": "#install-migro"
      }}>{`Install Migro`}</a></h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Ensure you have Python installed on your computer. Or you can `}<Link to='https://www.python.org/downloads/' target="_blank" mdxType="Link">{`download`}</Link>{` and install the latest version. Migro requires Python version 3.8 or higher.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Open the command line tool available on your OS. You could use PowerShell for Windows, or Terminal for Mac and Linux.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Run `}<code parentName="p" {...{
            "className": "lang-text"
          }}>{`pip --version`}</code>{` command to verify that you have pip up and running.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If you encounter problems, `}<Link to="https://packaging.python.org/en/latest/tutorials/installing-packages/#ensure-you-can-run-pip-from-the-command-line" target="_blank" mdxType="Link">{`ensure you can run pip from the command line`}</Link>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Run `}<code parentName="p" {...{
            "className": "lang-text"
          }}>{`pip install uploadcare-migro`}</code>{`.`}</p>
      </li>
    </ol>
    <p>{`Congratulations! Now we are ready to run Migro.`}</p>
    <Paragraph mdxType="Paragraph">
  <SectionWrapper mdxType="SectionWrapper">
    <Button as={Link} to='/accounts/signup/' onClick={() => track('Sign up')} type={ButtonTypeEnum.PRIMARY} outline={false} ghost mdxType="Button">
        Create an Uploadcare account
    </Button>
  </SectionWrapper>
    </Paragraph>
    <h2 {...{
      "id": "run-migro"
    }}><a parentName="h2" {...{
        "href": "#run-migro"
      }}>{`Run Migro`}</a></h2>
    <ol>
      <li parentName="ol">{`Create a text file somewhere on your computer with the links to all files you want to move to Uploadcare.
For the purpose of this guide, it’s going to be `}<code parentName="li" {...{
          "className": "lang-text"
        }}>{`files-for-uploadcare.txt`}</code>{` containing the following:`}</li>
    </ol>
    <Paragraph mdxType="Paragraph">
  <Code code={`https://cdn.filestackcontent.com/resize=width:1200/0zOaZQULQkerFOfwCyGU\nhttps://upload.wikimedia.org/wikipedia/commons/thumb/a/af/Olives_et_piments.jpg\n/1280px-Olives_et_piments.jpg\nhttps://giphy.com/clips/CloieWyattTaylor-coffee-i-need-cloie-wyatt-taylor-D9d49\nzzEPhh3sWRW7g\nhttps://media.giphy.com/media/51Uiuy5QBZNkoF3b2Z/giphy-downsized-large.gif
  `} mdxType="Code" />
    </Paragraph>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">
        <p parentName="li">{`Make sure you already have an `}<Link to="/accounts/signup/" target="_blank" mdxType="Link">{`Uploadcare account`}</Link>{` and a project created to keep files you plan to migrate.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Navigate to `}<code parentName="p" {...{
            "className": "lang-text"
          }}>{`https://app.uploadcare.com/projects/<your project id>/api-keys/`}</code>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Copy `}<strong>{`your public key`}</strong>{`.`}</p>
      </li>
    </ol>
    <Paragraph mdxType="Paragraph">
  <SectionWrapper mdxType="SectionWrapper">
    <Button as={Link} to='/accounts/signup/' onClick={() => track('Sign up')} type={ButtonTypeEnum.PRIMARY} outline={false} ghost mdxType="Button">
        Get your public key
    </Button>
  </SectionWrapper>
    </Paragraph>
    <img {...{
      "src": "https://ucarecdn.com/4720f9e7-cfe6-4a44-8287-6726c0f62e95/-/scale_crop/1200x642/-/quality/best/",
      "alt": "Uploadcare public key"
    }}></img>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Open your command line tool and run the following command:`}</li>
    </ol>
    <Paragraph mdxType="Paragraph">
  <Code code={`migro <your public key> <path to file with links>`} mdxType="Code" />
    </Paragraph>
    <p>{`  `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{` `}{`In our case, this was:`}</p>
    <Paragraph mdxType="Paragraph">
  <Code code='migro 7c3e642799177971a49c files-for-uploadcare.txt' mdxType="Code" />
    </Paragraph>
    <Paragraph mdxType="Paragraph">
  <SectionWrapper mdxType="SectionWrapper">
    <Button as={Link} to='/accounts/signup/' onClick={() => track('Sign up')} type={ButtonTypeEnum.PRIMARY} outline={false} ghost mdxType="Button">
        If you still don't have a key, get one here!
    </Button>
  </SectionWrapper>
    </Paragraph>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Wait for Migro to finish the transfer. You’ll see something like the following notice after the completion:`}</li>
    </ol>
    <Paragraph mdxType="Paragraph">
  <Code code={`
    migro 7c3e642799177971a49c files-for-uploadcare.txt\nUpload progress: 100%\|███████████████████████████████████████████████████\n█████████████████████████████████████████████████████████████████████████\n███████████████████████████████████████| 4/4 [00:01<00:00,  3.58file\/s]\nAll files have been processed, output URLs were written to: \nare here: /home/uploadcare/migro_result.txt\nNumber of failed files: 1\nThanks for your interest in Uploadcare.\nHit us up at help@uploadcare.com in case of any questions.
    `} mdxType="Code" />
    </Paragraph>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Investigate this migro_result.txt\` file for additional information about the files transfer. In the example above there is one failure: we tried to upload one non-image file to an unverified Uploadcare account. Verified accounts accept files of any type.`}</li>
    </ol>
    <p>{`Congratulations! You’ve just finished your file transfer. To discover more information about Migro capabilities, please read the `}<Link to="https://github.com/uploadcare/migro/blob/master/README.rst" target="_blank" mdxType="Link">{`instrument’s README`}</Link>{`.`}</p>
    <h2 {...{
      "id": "or-use-uploadcare-without-moving"
    }}><a parentName="h2" {...{
        "href": "#or-use-uploadcare-without-moving"
      }}>{`Or use Uploadcare without moving`}</a></h2>
    <p>{`If you are interested in Uploadcare features but not ready to move your files yet, please consider our `}<Link to="https://uploadcare.com/docs/delivery/proxy/" target="_blank" mdxType="Link">{`Proxy`}</Link>{`. The Proxy will allow you to keep your files where they are and get access to our CDN and operations, like `}<Link to="https://uploadcare.com/docs/transformations/image/#image-transformations-list" target="_blank" mdxType="Link">{`image processing`}</Link>{`.`}</p>
    <Paragraph mdxType="Paragraph">
  <SectionWrapper mdxType="SectionWrapper">
    <Button as={Link} to='/accounts/signup/' onClick={() => track('Sign up')} type={ButtonTypeEnum.PRIMARY} outline={false} ghost mdxType="Button">
        Don't forget to check out all our image operations!
    </Button>
  </SectionWrapper>
    </Paragraph>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      